import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { callApi } from "./callApi";
import { getProductDetail } from "../store/productDetail";



export default function GetProductData(startLimit, endLimit) {
    const dispatch = useDispatch();
  
    useEffect(() => {
      callApi("hmGetProductOwnerDevelopmentSteps", {
        startLimit: startLimit,
        endLimit: endLimit,
      }).then((res) => dispatch(getProductDetail(res?.tbl[0]?.r)));
    }, [dispatch]);

  }