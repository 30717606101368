import { Box, Grid, Switch, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {allTrueValue} from "../../store/productsBoolean"
import { allFalseValue } from "../../store/productsBoolean";
import img1 from "./Product_img/Logo1.png";
import img2 from "./Product_img/Logo2.png";
import img3 from "./Product_img/Logo3.png";
import img4 from "./Product_img/Logo4.png";
import img5 from "./Product_img/Logo5.png";
import img6 from "./Product_img/Logo6.png";
import img7 from "./Product_img/Logo7.png";
import img8 from "./Product_img/Logo8.png";

import ProductCard from "./ProductCard";
import GetData from "../../Api_Product_Detail/getData";
import { useDispatch, useSelector } 



from "react-redux";
function Product() {

  const dispatch = useDispatch()

  GetData();

  const { productDetail } = useSelector((state) => state.productDetail);
  const { productsBoolean } = useSelector((state) => state.productsBoolean);

  const detail1 = productDetail.find((e) => e.orderNo == "1.0");
  const detail2 = productDetail.find((e) => e.orderNo == "2.0");
  const detail3 = productDetail.find((e) => e.orderNo == "3.0");
  const detail4 = productDetail.find((e) => e.orderNo == "4.0");
  const detail5 = productDetail.find((e) => e.orderNo == "5.0");
  const detail6 = productDetail.find((e) => e.orderNo == "6.0");
  const detail7 = productDetail.find((e) => e.orderNo == "7.0");
  const detail8 = productDetail.find((e) => e.orderNo == "8.0");

  // const product1 = productsBoolean.find((e) => e.product1  )
  const product1Value = productsBoolean.product1;
  const product2Value = productsBoolean.product2;
  const product3Value = productsBoolean.product3;
  const product4Value = productsBoolean.product4;
  const product5Value = productsBoolean.product5;
  const product6Value = productsBoolean.product6;
  const product7Value = productsBoolean.product7;
  const product8Value = productsBoolean.product8;

  let product1 = "product1";
  let product2 = "product2";
  let product3 = "product3";
  let product4 = "product4";
  let product5 = "product5";
  let product6 = "product6";
  let product7 = "product7";
  let product8 = "product8";


  

  const MyTypography = styled(Typography)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 0px 10px",

    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });

  const Typography2 = styled(Typography)({
    marginBottom: "40px",
    color: "#333333",
    fontFamily: "Open Sans",
    fontWeight: "500",
    fontSize: "15px",
    padding:"0 10px"
  });

  //   Box2-Image Part Start

  const BoxContact = styled(Box)({
    position: "relative",
    top: "44px",
    width: "30%",
    height: "2px",
    background: "#3399EE",
  });

  const BoxTriangle2 = styled(Box)({
    position: "relative",
    top: "36px",
    width: "0%",
    height: "0px",
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderLeft: "18px solid #3399EE",
    marginLeft: "10px",
  });

  const BoxCircle = styled(Box)({
    position: "relative",
    top: "41px",
    width: "9px",
    height: "8px",
    borderRadius: "50%",
    background: "#3399EE",
  });

  //   Box2-Image Part End

  const [checked, setChecked] = useState(false);
 
  const handleChecked = (event) => {
    setChecked(!checked)
    
    dispatch(allFalseValue())
  };


  useEffect(() => {
    if(checked){
      dispatch( allTrueValue())
     
    }
  } , [checked])

  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
      <MyTypography>Roadmap of a Product Owner</MyTypography>
      <Typography2 align='center'>
        AI based Knowledge Management and Training Simulation system <br /> will
        help you to obtain the learned topics fast and easily. <br /> Each step
        has several case studies.
      </Typography2>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginBottom: "15px",
        }}
      >
        <Typography sx={{ fontFamily: "Open Sans", color: "#333333" }}>
          Show All{" "}
        </Typography>
        <Switch
          checked={checked}
          onChange={handleChecked}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>

      {/* Part 1 start */}

      <Grid container rowSpacing={10}>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", margin:"0 3px" }}>
            <img src={img1} alt='' />
            <BoxContact></BoxContact>
            <BoxCircle></BoxCircle>
            <BoxTriangle2></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              // esas

              productName={product1}
              productValue={product1Value}
              detail={detail1}
              color={"#3399FF"}
            />
          </Box>
        </Grid>

        {/* Part 1 end */}

        {/* Part 2 start */}

        <Grid item xs={4}>
          <Box sx={{ display: "flex", margin:"0 3px" }}>
            <img src={img2} alt='' />
            <BoxContact sx={{ background: "#52A300" }}></BoxContact>
            <BoxCircle sx={{ background: "#52A300" }}></BoxCircle>
            <BoxTriangle2
              sx={{ borderLeft: "18px solid #52A300" }}
            ></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              detail={detail2}
              color={"#52A300"}
              productName={product2}
              productValue={product2Value}
            />
          </Box>
        </Grid>

        {/* Part 2 end */}

        {/* Part3 start */}
        <Grid item xs={4}>
          <Box sx={{ display: "flex", margin:"0 3px" }}>
            <img src={img3} alt='' />
            <BoxContact sx={{ background: "#D4D400" }}></BoxContact>
            <BoxCircle sx={{ background: "#D4D400" }}></BoxCircle>
            <BoxTriangle2
              sx={{ borderLeft: "18px solid #D4D400" }}
            ></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              detail={detail3}
              color={"#D4D400"}
              productName={product3}
              productValue={product3Value}
            />
          </Box>
        </Grid>

        {/* Part3 end */}

        {/* Part4 start */}

        <Grid item xs={4}>
          <Box sx={{ display: "flex", margin:"0 3px" }}>
            <img src={img4} alt='' />
            <BoxContact sx={{ background: "#E87400" }}></BoxContact>
            <BoxCircle sx={{ background: "#E87400" }}></BoxCircle>
            <BoxTriangle2
              sx={{ borderLeft: "18px solid #E87400" }}
            ></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              detail={detail4}
              color={"#E87400"}
              productName={product4}
              productValue={product4Value}
            />
          </Box>
        </Grid>

        {/* Part4 end */}

        {/* Part5 start */}

        <Grid item xs={4}>
          <Box sx={{ display: "flex",  margin:"0 3px"}}>
            <img src={img5} alt='' />
            <BoxContact sx={{ background: "#CC0000" }}></BoxContact>
            <BoxCircle sx={{ background: "#CC0000" }}></BoxCircle>
            <BoxTriangle2
              sx={{ borderLeft: "18px solid #CC0000" }}
            ></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              detail={detail5}
              color={"#CC0000"}
              productName={product5}
              productValue={product5Value}
            />
          </Box>
        </Grid>

        {/* Part5 end */}

        {/* Part6 start */}

        <Grid item xs={4} >
          <Box sx={{ display: "flex", margin:"0 3px" }}>
            <img src={img6} alt='' />
            <BoxContact sx={{ background: "#000080" }}></BoxContact>
            <BoxCircle sx={{ background: "#000080" }}></BoxCircle>
            <BoxTriangle2
              sx={{ borderLeft: "18px solid #000080" }}
            ></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              detail={detail6}
              color={"#000080"}
              productName={product6}
              productValue={product6Value}
            />
          </Box>
        </Grid>

        {/* Part6 end */}

        {/* Part7 start */}

        <Grid item xs={4}>
          <Box sx={{ display: "flex", margin:"0 3px"}}>
            <img src={img7} alt='' />
            <BoxContact sx={{ background: "#647687" }}></BoxContact>
            <BoxCircle sx={{ background: "#647687" }}></BoxCircle>
            <BoxTriangle2
              sx={{ borderLeft: "18px solid #647687" }}
            ></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              detail={detail7}
              color={"#647687"}
              productName={product7}
              productValue={product7Value}
            />
          </Box>
        </Grid>

        {/* Part7 end */}

        {/* Part8 start */}

        <Grid item xs={4}>
          <Box sx={{ display: "flex", margin:"0 3px" }}>
            <img src={img8} alt='' />
            <BoxContact sx={{ background: "#6A24B0" }}></BoxContact>
            <BoxCircle sx={{ background: "#6A24B0" }}></BoxCircle>
            <BoxTriangle2
              sx={{ borderLeft: "18px solid #6A24B0" }}
            ></BoxTriangle2>
          </Box>
        </Grid>

        <Grid item xs={5}>
          <Box
            sx={{ display: "flex", alignItems: "start", position: "relative" }}
          >
            <ProductCard
              isTrue={checked}
              setIsTrue={setChecked}
              detail={detail8}
              color={"#6A24B0"}
              productName={product8}
              productValue={product8Value}
            />
          </Box>
        </Grid>

        {/* Part8 end */}
      </Grid>


    </Box>
  );
}

export default Product;
