import {
  Box,
  Button,
  Grid,
  ListItem,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import React from "react";
// import diagram from "../../assets/image/Video_2023_02_14-3.gif";
import Part1Icon from "./img/Part1Icon.png";
import Part2Icon from "../../assets/Part2Image/User-Story-Card.png"
import Part3Icon from "../../assets/Part2Image/Sprint-Icon.png"
import Part4Icon from "../../assets/Part2Image/Api-Icon.png"
import Part5Icon from "../../assets/Part2Image/Entitty.png"
import Part6Icon from "../../assets/Part2Image/Process.png"
import Part7Icon from "../../assets/Part2Image/Flow-Icon.webp"
import Part8Icon from "../../assets/Part2Image/Role-Map.png"

function Part1() {
  const handleButtonClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const myImage = {
color:"#333333"
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1C2128",
        second: "#888888",
      },
      secondary: {
        main: "#373C44",
      },
    },
  });

  const MyTypography = styled(Typography)({
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "left",
    margin: "4px 0px 2px 0px",
    
    "@media and (max-width:600px )": {
      display: "none",
    },
  });

  const MyTypography2 = styled(Typography)({
    marginY: "10px",
    fontSize: 14,
    color: theme.palette.primary.second,
    fontFamily: "Open Sans",
  });

  const MyButton = styled(Button)({
    fontSize: "16px",
    height: "56px",
    width: "222px",
    backgroundColor: "#4691db",
    color: "white",
    fontFamily: "Open Sans",
    fontWeight: 700,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4691db",
      color: "white",
    },
    "@media (max-width:665px)": {
      width: "150px",
      height: "45px",
    },
  });

  return (
    <>
      <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box>
                <img style={myImage} width={50} src={Part1Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>Business Case</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Segmentation, Provided Services, Customer Requirements, Financial Statement, Competitor Analysis, Advantages,Technical Advantages, Value Proposition,Business Model,Revenue Stoppers</MyTypography2>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box  >
                <img style={myImage}  width={50} src={Part2Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>User Story Card</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Form View, Input, Input Descriptions, Prototype, Story Card Flow</MyTypography2>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box  >
                <img style={myImage}  width={50} src={Part7Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>User Story Flow</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Event Action Flow Criteria, Figma Integration, UX Flow , CRUD Flow</MyTypography2>
              </Box>
            </Box>
          </Grid>
      
          <Grid item xs={12} sm={6}>
            <Box>
              <Box  >
                <img style={myImage}  width={50} src={Part8Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>Role Map Diagram</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Business Roles, Process Time, Execution Time, Risk List, Robotization Standards, Initiator Oriented Services</MyTypography2>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box>
                <img style={myImage} width={50} src={Part3Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>Backlog/Sprint Matrix</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Prioritization of  User Stories among Sprints. Matrix shows a big picture and relation between Backlog and Sprints.</MyTypography2>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box>
                <img style={myImage} width={50} src={Part4Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>API Card</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Input, Input Descriptin, Operation Description, Output, Output Description, Related User Stories.</MyTypography2>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box>
                <img style={myImage} width={60} src={Part5Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>Database Card/Entity Diagram</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Table  Structure, Description, Foreign Key Relation, Entity Relation per Flow.</MyTypography2>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Box>
                <img style={myImage} width={50} src={Part6Icon} alt='' />
              </Box>
              <Box>
                <MyTypography>Process Card.</MyTypography>
              </Box>
              <Box>
                <MyTypography2>Flow Diagram, User Actors, User Stories, API, Entity, Conditions, Component Direct View, Form-API Relation, Entity-API Relation.</MyTypography2>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Part1;
