import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./pages/Mainpage.jsx/Home";
import ContactSimple from "./pages/contact/ContactSimple/ContactSimple";
import Price from "./pages/Price";
import AllCourseInfo from "./pages/AllCourse/AllCourse";
import Footer from "./components/Footer/Footer";
import LoadToTop from "./pages/LoadToTop/LoadToTop";
import AllTeam from "./pages/AllTeam_Page/AllTeam";
import Profile from "./pages/Profile/Profile";
import Product from "./pages/Product_Owner/Product";


function App() {


  return (
    <div className='App'>
      <LoadToTop />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/price' element={<Price />} />
        <Route path='/samples' element={<AllCourseInfo />} />
        <Route path='/polist' element={<AllTeam />} />
        <Route path='/profile/:id' element={<Profile/>}  />
        <Route path='/productowner' element={<Product/>}  /> 
      </Routes>
      {/* <Home/> */}
      <Footer />
    </div>
  );
}

export default App;
