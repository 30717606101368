import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  ListItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GetData from "../../Api_mentor/getData";
import { useSelector } from "react-redux";
import AllTeamCard from "./AllTeamCard";
import { Link } from "react-router-dom";

function AllTeam() {

  GetData(0,10);

  const { allMentor } = useSelector((state) => state.allMentor);



  const MyTypography = styled(Typography)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 0px 10px",

    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });

  return (
    <Box sx={{ margin: "0 auto", maxWidth: "800px", minHeight:"70vh" }}>
      <MyTypography>Meet Our Experts</MyTypography>
      <Typography sx={{marginBottom:"40px", padding:"0 20px"}} align="center">
        Our expert can foster your business to be successful and <br /> find innovative solutions
      </Typography>

      <Grid container spacing={3} sx={{padding:{xs:"0 20px" , sm:"0"}}} >
        {allMentor?.map((index, key) => (
          <Grid  key={key} item xs={12} sm={4}>
            <AllTeamCard  index={index}/>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default AllTeam;
