import { createSlice } from '@reduxjs/toolkit'

const initialState = {
serviceList:[]
}

const allServiceList = createSlice({
  name: "serviceList",
  initialState,
  reducers: {
    getServiceList:(state, action)=>{
        state.serviceList = action.payload
      },

  }
});

export const {getServiceList} = allServiceList.actions

export default allServiceList.reducer