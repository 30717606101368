import { Box, Container, Typography, createTheme, styled } from "@mui/material";
import React from "react";
import Percent from "./Percent";
import WhatPeopleSay from "./WhatPeopleSay";
import ContactSimple from "../contact/ContactSimple/ContactSimple";
import Part1 from "./Part1";
import Part2 from "./Part2";
import Part3 from "./Part3";
import WhyHekayem from "./WhyHekayem";
import MentorCard from "./Mentor_part/MentorMain";
import { useSelector } from "react-redux";
import GetData from "../../Api_mentor/getData";
import Expert from "./Our_Expert/Expert";
import Checked from "./CheckedPart/Checked";

function Home() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#333333",
        second: "#888888",
      },
      secondary: {
        main: "#373C44",
      },
    },
  });

  const MyTypography = styled(Typography)({
    color: theme.palette.primary.main,
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 30px 20px ",

    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });

  const MyTypography4 = styled(Typography)({
    color: theme.palette.primary.second,
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "Open Sans",
    "@media (max-width:665px)": {
      fontSize: "16px",
    },
    marginBottom: "20px",
  });

  return (
    <React.Fragment>
      <Container>
        <MyTypography>
          Digital Product Summary is a Guide for Starting Coding
        </MyTypography>

        <Part3 />

        {/* Part3 */}

        {/* Part1 */}
        <MyTypography>What is Digital Product Summary?</MyTypography>
        <MyTypography4>
          Digital Product Summary shows every details of the product.{" "}
        </MyTypography4>

        <Part1 />

        {/* Part2 */}

        <MyTypography sx={{ marginBottom: "50px" }}>
          Digital Product Summary Samples
        </MyTypography>

        <MentorCard />

        <Expert />

        <MyTypography>Why hekayam.com</MyTypography>

        <MyTypography4>
          We have developed a breakthrough, innovative and , <br /> AI based
          simulation platform in order to create a prototype and MVP of the
          digital products. <br /> That gives us opportunity to analyze the products
          from a different perspective. <br /> After 10+ years of the research we have
          following: following:
        </MyTypography4>

        {/* Part4 */}
        <Percent />

        {/*  */}
        {/* <section id='homePart1'>
          <WhyHekayem />
        </section> */}

        {/*  */}

        {/* <MyTypography>What people says about us.</MyTypography> */}

        {/* Part5 */}

        {/* <WhatPeopleSay /> */}
        {/* <MyTypography>How can we help you ?</MyTypography>  */}

        {/* <Checked/> */}

        {/* Contact  */}

        <ContactSimple />
      </Container>

      {/* Footer */}

      {/* Footer */}
    </React.Fragment>
  );
}

export default Home;
