import {
  Box,
  ListItem,
  Stack,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import React from "react";

function WhyHekayem() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1C2128",
        second: "#888888",
      },
      secondary: {
        main: "#373C44",
      },
    },
  });

  const MyTypography = styled(Typography)({
    color: theme.palette.primary.main,
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "30px",

    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });

  const MyListItem = styled(ListItem)({
    color: theme.palette.primary.second,
    display: "flex",
    justifyContent: "start",
    marginLeft: 3,
    position: "relative",
    "&:after": {
      position: "absolute",
      content: "''",
      left: "0",
      width: "9px",
      height: "9px",
      backgroundColor: "#373C44",
      borderRadius: "50%",
    },
  });

  return (
    <>
      <MyTypography sx={{ marginTop: 8 }}>Why hekayam.com </MyTypography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Stack>
          <MyListItem>AI based integration of several tools,</MyListItem>
          <MyListItem>
            AI based, Innovative and Breakthrouth cards (Business Card, Process
            Card, Story Card and Data Card) <br /> makes requirement analysis
            faster and precise.
          </MyListItem>
          <MyListItem>
            Business Card provides detailed Business Model of customer.
          </MyListItem>
          <MyListItem>
            Story Card provides clear communition amount Business and Software
            specialist.
          </MyListItem>
          <MyListItem>
            Process Card provides a detailed big picture of each provided
            services.
          </MyListItem>
          <MyListItem>
            Data Card provides entire Entity Diagram and their relation with
            Story Cards.
          </MyListItem>
        </Stack>
      </Box>
    </>
  );
}

export default WhyHekayem;
