import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { callApi } from "./callApi";
import { getDataMentor } from "../store/allMentor";
import { getHomePageMentor } from "../store/allMentor";


export default function GetData(startLimit, endLimit) {
    const dispatch = useDispatch();
  
    useEffect(() => {
      callApi("csGetProductOwnerProfileListNew", {
        startLimit: startLimit,
        endLimit: endLimit,
      }).then((res) => dispatch(getDataMentor(res?.tbl[0]?.r)));
    }, [dispatch]);
  }