import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  ListItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
// import { Tooltip } from "@material-ui/core";
import Tooltip from '@mui/material/Tooltip';
import priceImage from "../assets/PriceImage/PriceImage.png";
import priceImage2 from "../assets/PriceImage/PriceImage2.png";
import priceImage3 from "../assets/PriceImage/PriceImage3.png";

function Price() {
  const BusinessCase = [
    "Segmentation",
    "Provided Services",
    "Customer Requirements",
    "Financial Statement",
    "Competitor/Advantages",

    "Features/Disadvantages",
    "Technical Advantages",
    "Value Proposition",
    "Business Model",
    "Revenue Stoppers",
  ];

  const SystemAnalysis = [
    "Use Cases Diagrams",
    "Mockups",
    "Process Flow Diagram",
    "UX standards",
    "Page View",
    "UAT (User Acceptance Criterias)",
    "MVP",
  ];

  const ArchitectureAnalysis = [
    "Mockups",
    "Form",
    "Input-API Relations",
    "Form-Database Relations",
    "APIs",
    "DB Structure",
    "Entity Diagram",
    "Flow Diagram",
    "API Management",
  ];

  const customStyles = {
    fontSize: "14px",
  };

  const MyPrice = styled(Typography)({
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    fontSize: "52px",
    color: "#333333",
    fontWeight: "700",
    position: "relative",
    fontFamily: "Open Sans",
    paddingTop: "15px",
    "&:after": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "0.5px",
      backgroundColor: "#c9d2db",
      top: "9px",
    },
  });

  const MyList = styled(ListItem)({
    fontSize: "14px",
    position: "relative",
    paddingBottom: "1px",
    "&:before": {
      position: "absolute",
      content: '""',
      width: "20px",
      height: "20px",
      backgroundImage: `url("./Mainpage.jsx/img/checkicon.png")`,
    },
  });



  return (
    <Box sx={{ maxWidth: "880px", margin: "0 auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} >
          <Card sx={{   boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img className='priceImage' src={priceImage} alt='' />
              </Box>
              <Typography
                align='center'
                sx={{
                  fontWeight: "600",
                  fontSize: "19.5px",
                  marginBottom: "35px",
                  fontFamily: "Open Sans",
                }}
                color='text.secondary'
                gutterBottom
              >
                Business Case <br /> Analysis
              </Typography>
              <Typography variant='h5' component='div'></Typography>
              <Tooltip
                style={customStyles}
                title='Each session a several closed Question will be asked regarding the following items. After completing sessions within 4-7 days the Business Case Analysis Document will be provided . 
A Number of Potential/Real Customers, Revenue Stream is NOT including
'
                placement='bottom'
              >
                <Typography
                  align='center'
                  sx={{
                    fontSize: "13px",
                    marginBottom: "19px",
                    // lineHeight: "0.1px",
                  }}
                  color='text.secondary'
                >
                  Each session a several closed Question will be asked
                  regarding...
               
                </Typography>
              </Tooltip>

              <MyPrice>
                $29
                <Typography
                  sx={{
                    fontSize: "12px",
                    display: "inline",
                    position: "absolute",
                    bottom: "0px",
                    right:"34px"
                  }}
                >
                  /session
                </Typography>
              </MyPrice>

              <Stack>
                {BusinessCase.map((index, key) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ marginLeft: "10px" }} />{" "}
                    <MyList key={key}>{index}</MyList>
                  </Box>
                ))}
              </Stack>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
               marginTop:"56px",
               marginBottom:"15px"
              }}
            >
              {/* <Button variant='contained'> Contact</Button> */}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{   boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img className='priceImage' src={priceImage2} alt='' />
              </Box>
              <Typography
                align='center'
                sx={{
                  fontWeight: "600",
                  fontSize: "19.5px",
                  marginBottom: "5px",
                  fontFamily: "Open Sans",
                  minHeight: "89px",
                }}
                color='text.secondary'
                gutterBottom
              >
                System Analysis and <br /> Design
              </Typography>
              <Typography variant='h5' component='div'></Typography>
              <Tooltip
                style={customStyles}
                title='Bysiness Case Analysis Document should be ready as a main requirement. After completing sessions within 10-14 days the System Analysis & Design Document will be ready. 
'
                placement='bottom'
              >
                <Typography
                  align='center'
                  sx={{
                    fontSize: "13px",
                    marginBottom: "19px",
                    // lineHeight: "0.1px",
                  }}
                  color='text.secondary'
                >
                  Bysiness Case Analysis Document should be ready as a main ...
                
                </Typography>
              </Tooltip>

              <MyPrice>
                39 $
                <Typography
                  sx={{
                    fontSize: "12px",
                    display: "inline",
                    position: "absolute",
                    bottom: "0px",
                    right:"0px"
                  }}
                >
                  /Page (Story Card)
                </Typography>
              </MyPrice>

              <Stack sx={{ minHeight: "320px" }}>
                {SystemAnalysis.map((index, key) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ marginLeft: "10px" }} />{" "}
                    <MyList key={key}>{index}</MyList>
                  </Box>
                ))}
              </Stack>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "15px 0",
              }}
            >
              {/* <Button variant='contained'> Contact</Button> */}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card sx={{   boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}>
            <CardContent>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img className='priceImage' src={priceImage3} alt='' />
              </Box>
              <Typography
                align='center'
                sx={{
                  fontWeight: "600",
                  fontSize: "19.5px",
                  marginBottom: "5px",
                  fontFamily: "Open Sans",
                  minHeight: "89px",
                }}
                color='text.secondary'
                gutterBottom
              >
                Architecture Analysis and Modeling
              </Typography>
              <Typography variant='h5' component='div'></Typography>
              <Tooltip
                style={customStyles}
                title='System Analysis & Design Document should be ready as a main requirement. After completing sessions within 10-14 days the Architecture Analysis and Modeling Document will be ready. 
'
                placement='bottom'
              >
                <Typography
                  align='center'
                  sx={{
                    fontSize: "13px",
                    marginBottom: "19px",
                    // lineHeight: "0.1px",
                  }}
                  color='text.secondary'
                >
                  System Analysis & Design Document should be ready as a main
                  ...
               
                </Typography>
              </Tooltip>

              <MyPrice>
                99 $
                <Typography
                   sx={{
                    fontSize: "12px",
                    display: "inline",
                    position: "absolute",
                    bottom: "0px",
                    right:"0px"
                  }}
                >
                  /Page (Story Card)
                </Typography>
              </MyPrice>

              <Stack sx={{ minHeight: "320px" }}>
                {ArchitectureAnalysis.map((index, key) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CheckIcon sx={{ marginLeft: "10px" }} />{" "}
                    <MyList key={key}>{index}</MyList>
                  </Box>
                ))}
              </Stack>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "15px 0",
              }}
            >
              {/* <Button variant='contained'> Contact</Button> */}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Price;
