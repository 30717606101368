import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { callApi } from "./callApi";
import { getData } from "../store/allCourse";
import { getHomePage } from "../store/allCourse";


export default function GetData(startLimit , endLimit , authorId) {
  const dispatch = useDispatch();

  useEffect(() => {
    callApi("GetDigitalProductSummarySampleList", {
      startLimit: startLimit,
      endLimit: endLimit,
      fkAuthorId:authorId
    }).then((res) => dispatch(getData(res?.tbl[0]?.r)));
  }, [dispatch]);



}
