import { Box, Grid, Stack, Typography, createTheme, styled } from "@mui/material";
import React, { useState } from "react";
import Pimg1 from "./img/PercentPageImg/1.png";
import Pimg2 from "./img/PercentPageImg/2.png";
import Pimg3 from "./img/PercentPageImg/3.png";
import Pimg4 from "./img/PercentPageImg/4.png";
import Pimg5 from "./img/PercentPageImg/5.png";
import Pimg6 from "./img/PercentPageImg/8.1.jpg";
import Pimg7 from "./img/PercentPageImg/6.png";
import Pimg8 from "./img/PercentPageImg/7.png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
function Percent() {
  const [counterOn, setCounterOn] = useState(false);

  const imagStyle = {
    width: "48px",
    height: "46px",
  };
  const mySpan = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "20px" , fontFamily: "Open Sans",
    color: "#243763",
  };

  const MyTypography3 = styled(Typography)({
    display: "inline",
    fontSize: 40,
    color: "#ff761c",
    fontWeight: "700",
    fontFamily: "Open Sans",
  });

  const GridBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    gap: 10,
  });

  const MyGrid = styled(Grid)({
    "@media ((min-width: 992px) and (max-width: 1200px) )": {
      maxWidth: "800px",
    },
  });
  const MyBox =styled(Box)({
    position:"relative",
    display:"flex",
    flexDirection:"column",
    minHeight:"100px",
    
    '&::after':{
      position:"absolute",
      content:"'up to'",
      top:"45px",
      right:"35px",
      fontWeight:"400",
      fontSize:16
    }
  })

  const MyBox2 = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",

    "@media screen and (min-width: 665px) and (max-width: 992px)":{
      minWidth: "210px",
    }


    ,"@media screen and (min-width: 992px) and (max-width: 1200px)":{
      minWidth: "210px",
      
    },
    
    "@media screen and (min-width: 1200px)":{
      minWidth: "300px",
    }
  })




  return (
    <MyGrid
      container
      spacing={1}
      sx={{
        display: "flex",
        justifyContent: "center",
        borderRadius: "25px",
        mt: 3,
        pr: 1,
        maxWidth: "1000px",
        margin: "0 auto",
      }}
    >
      
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg1} alt='' />
          </Box>
          <MyBox2
      
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>
              Requirement Analysis Accuracy
            </Typography>
          </MyBox2>
          <MyBox  >
            <ScrollTrigger sx={{display:"none"}}
              onEnter={() => setCounterOn(true)}
              // onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp  start={0} end={90} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
            {/* <MyTypography2>up to</MyTypography2> */}
            </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg2} alt='' />
          </Box>
          <MyBox2
        
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>
              Form Design Accurasy
            </Typography>
          </MyBox2>
          <MyBox>
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp start={0} end={90} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
            
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg4} alt='' />
          </Box>
          <MyBox2
        
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>
              Project Duration Reduction
            </Typography>
          </MyBox2>
          <MyBox>
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp start={0} end={70} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
            
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg3} alt='' />
          </Box>
          <MyBox2
          sx={{minWidth: "220px"}}
       
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>Cost Reduction</Typography>
          </MyBox2>
          <MyBox>
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp start={0} end={70} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
           
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg5} alt='' />
          </Box>
          <MyBox2
        
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>
              Solution Architecture Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp start={0} end={90} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
          
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg6} alt='' />
          </Box>
          <MyBox2
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>
              Project Manegment Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp start={0} end={90} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
           
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg7} alt='' />
          </Box>
          <MyBox2
         
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>
              Documentation Accuracy
            </Typography>
          </MyBox2>
          <MyBox>
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp start={0} end={90} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
         
          </MyBox>
        </GridBox>
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <GridBox>
          <Box>
            <img style={imagStyle} src={Pimg8} alt='' />
          </Box>
          <MyBox2
        
          >
            <Typography sx={{ fontSize: "20px" , fontFamily: "Open Sans" }}>
              Quality Assurance Accuracy
            </Typography>
          </MyBox2>
          <MyBox >
          <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              {
                counterOn &&
                <MyTypography3>
                <CountUp start={0} end={90} duration={2} delay={0} />%
              </MyTypography3>
              }
            </ScrollTrigger>
          
          </MyBox>
        </GridBox>
      </Grid>
    </MyGrid>
  );
}

export default Percent;
