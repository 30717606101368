import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import avatar from "./img/avatar1.webp";
import avatar2 from "./img/avatar2.jpg";
import avatar3 from "./img/avatar3.jpg";
import avatar4 from "./img/avatar4.jpg";

function WhatPeopleSay() {
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center", }}
      >
        <Grid item xs={12} sm={9}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ borderRadius: 2, backgroundColor: "#ebebeb", p: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{}}>
                  I will increase the use of the RA document at work and adopt
                  more techniques especially around Business Model Analysis. The
                  strengths of the document for me were the inteaction among
                  domains, structure and additional but relevant information
                  around stakeholder management.
                </Typography>
                <Avatar
                  sx={{ width: 70, height: 70, right: "10px" }}
                  alt='Steven Almond'
                  src={avatar3}
                />
              </Box>

              <Typography sx={{ fontSize: "20px", mt: "10px" }}>
                Ankush Manuja, India
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={9}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ borderRadius: 2, backgroundColor: "#ebebeb ", p: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Avatar
                  sx={{ width: 70, height: 70, right: "10px" }}
                  alt='Steven Almond'
                  src={avatar2}
                />
                <Typography sx={{}}>
                  I was able to use the RA Document at work to great effect,
                  beginning immediately with the business model and continuing
                  through acceptance criterias. The document layout and provided
                  structure were great and very practical at work!
                </Typography>
              </Box>

              <Typography sx={{ fontSize: "20px", mt: "10px" }}>
                Andy Molnar, Canada
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ borderRadius: 2, backgroundColor: " #ebebeb", p: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{}}>
                  Definitely the best requirement analysis format I’ve ever
                  used. The strengths of this document are the variety in
                  demonstrating information. Great document template and service
                  to be honest!
                </Typography>
                <Avatar
                  sx={{ width: 70, height: 70, right: "10px" }}
                  alt='Steven Almond'
                  src={avatar4}
                />
              </Box>

              <Typography sx={{ fontSize: "20px", mt: "10px" }}>
                Murad Basharli, USA
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default WhatPeopleSay;
