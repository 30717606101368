import { configureStore } from "@reduxjs/toolkit";
import allCourse from "./allCourse";
import allMentor from "./allMentor";
import productDetail from "./productDetail";
import productsBoolean from "./productsBoolean";
import serviceList from "./allServiceList"

export const store = configureStore({
    reducer:{
        allCourse:allCourse,
        allMentor:allMentor,
        productDetail:productDetail,
        productsBoolean:productsBoolean,
        serviceList:serviceList
        
    }
})