import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productsBoolean: {
    product1: false,
    product2: false,
  },
};

const productsBoolean = createSlice({
  name: "productsBoolean",
  initialState,
  reducers: {
    changeProductValue: (state, action) => {
      const { productName, productValue } = action.payload;
      console.log(productName, productValue);

      state.productsBoolean[productName] = productValue

 
    },

    allTrueValue:(state) =>{
      state.productsBoolean ={...state.productsBoolean,
        product1: true ,
        product2: true,
        product3: true,
        product4: true,
        product5: true,
        product6: true,
        product7: true,
        product8: true,
      }
    },
    allFalseValue:(state) =>{
      state.productsBoolean ={...state.productsBoolean,
        product1: false ,
        product2: false,
        product3: false,
        product4: false,
        product5: false,
        product6: false,
        product7: false,
        product8: false,
      }
    }
  },
});

export const { changeProductValue, allTrueValue , allFalseValue} = productsBoolean.actions;

export default productsBoolean.reducer;
