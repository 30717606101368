import {
  Box,
  Button,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProductContact from "./ProductContact/ProductContact";
import { useDispatch } from "react-redux";
import { changeProductValue } from "../../store/productsBoolean";

function ProductCard(props) {
  let { detail, color, productName, productValue } = props;

  const dispatch = useDispatch();

 

  const BoxLetter = styled(Box)({
    borderRadius: "10px",
  });

  const Box4 = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    background: "#e4e4e4",
    flexDirection: "column",
    padding: "10px",
    // width:"80%"
  });
  const Box5 = styled(Box)({
    marginBottom: "10px",
    position: "relative",
  });

  const Box6 = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
  });

  const MainTypography = styled(Typography)({
    padding: "6.3px 10px ",
    textAlign: "left",
    fontSize: "22px",
    fontFamily: "Open Sans",
    background: "#e4e4e4",
    borderLeft: `4px solid ${color}`,
    borderBottom: ` 1px solid  ${color}`,
    fontWeight: "600",
    color: "#333333",

    // borderTopLeftRadius: "10px",
    // borderTopRightRadius: "10px",
  });

  const Typography1 = styled(Typography)({
    fontSize: "12px",
    color: "black",
    display: "flex",
    alignItems: "center",
    minWidth: "100px",
    fontWeight: "700",
    marginRight: "10px",
    fontFamily: "Open Sans",
  });
  const Typography2 = styled(Typography)({
    fontSize: "12px",
    color: "grey",
    position: "relative",
    top: "1px",
    fontFamily: "Open Sans",
  });
  const Typography3 = styled(Typography)({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontSize: "14px",
  });

  const PriceBox = styled(Typography)({
    display: "flex",
    alignItems: "center",
  });

  const PriceTypo = styled(Typography)({
    display: "inline",
    fontSize: "14px",
    marginLeft: "10px",
    position: "relative",
    top: "2px",
    color: "#1565C0",
  });

  const OldPriceTypo = styled(Typography)({
    fontSize: "12px",
    position: "relative",
    top: "-7px",
    marginLeft: "10px",
    textDecoration: "line-through",
  });

  const MyBtn = styled(Button)({
    textTransform: "none",
    marginRight: "10px",
    padding: "2px",
    fontSize: "14px",
  });

  const [openCntc, setOpenCntc] = useState(false);

  const openContact = () => {
    setOpenCntc(!openCntc);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = (e) => {
    productValue = !productValue;

    dispatch(changeProductValue({ productName, productValue }));
  };

  const RawHtml = ({ children = "", tag: Tag = "div", ...props }) => (
    <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
  );

  return (
    <>
      <BoxLetter sx={{  minWidth:{xs:"240px", sm:"430px", lg:"470px"} }}>
        <MainTypography>{detail?.stepName}</MainTypography>
        <ProductContact
          openCntc={openCntc}
          setOpenCntc={setOpenCntc}
          detail={detail?.stepName}
        />
        <Box4>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PriceBox>
              <MyBtn onClick={() => openContact()} variant='contained'>
                Join{" "}
              </MyBtn>
              <PriceBox component='span'>
                <PriceTypo>{detail?.payment}$ </PriceTypo>
                <OldPriceTypo>{detail?.discountPayment}$</OldPriceTypo>
              </PriceBox>
            </PriceBox>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {productValue ? (
                <Typography3 sx={{}} onClick={() => handleClick(productName)}>
                  {" "}
                  More <KeyboardArrowUpIcon />{" "}
                </Typography3>
              ) : (
                <Typography3 onClick={() => handleClick()}>
                  {" "}
                  More <KeyboardArrowDownIcon />{" "}
                </Typography3>
              )}
            </Box>
          </Box>

          <Box sx={{ position: "relative" }}>
            {/* <Box> */}
            <Box>
              {productValue ? (
                <Box sx={{ padding: " 15px 10px" }}>
                  <Box5>
                    <Box6>
                      <Typography1>Duration: </Typography1>
                      <Typography2>{detail?.duration}</Typography2>
                    </Box6>

                    <Box6>
                      <Typography1>Mentor Hours:</Typography1>
                      <Typography2>{detail?.mentorHours}</Typography2>
                    </Box6>

                    <Box6>
                      <Typography1>Assignment Hours (Case Study):</Typography1>
                      <Typography2>32 Hours</Typography2>
                    </Box6>

                    <Box6>
                      <Typography1>
                        Individual Final Project Duration:
                      </Typography1>
                      <Typography2>{detail?.finalProjectDuraction}</Typography2>
                    </Box6>

                    <Box6>
                      <Typography1>Number of Case Studies:</Typography1>
                      <Typography2>{detail?.nOfCaseStudies}</Typography2>
                    </Box6>

                    <Typography1 sx={{ margin: "15px 0px" }}>
                      You will learn:
                    </Typography1>
                    <Box sx={{ paddingLeft: "12px" }}>
                      <RawHtml tag={"span"} style={{ fontSize: "12px" }}>
                        {detail?.youWillLearn}
                      </RawHtml>
                    </Box>
                  </Box5>
                </Box>
              ) : null}
            </Box>
            {/* </Box> */}
          </Box>
        </Box4>
      </BoxLetter>
    </>
  );
}

export default ProductCard;
