import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import GetData from "../../Api_mentor/getData";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GetAllCourse from "../../Api/getData";
import MentorCard from "../Mainpage.jsx/Mentor_part/MentorCard";

function Profile() {
  const myImage = {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
  };

  const { id } = useParams();

  GetData();

  GetAllCourse(0, 100, id);

  const { allCourse } = useSelector((state) => state.allCourse);

  console.log(allCourse);

  const { allMentor } = useSelector((state) => state.allMentor);

  const profile = allMentor.find((e) => e.id == id);

  console.log(profile);

  const MyTypography = styled(Typography)({
    fontSize: "18px",
    fontWeight: 700,
    marginTop: "20px",
    fontFamily: "Open Sans",
  });
  const MyTypographyMain = styled(Typography)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 0px 10px",

    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });

  const MyTypography2 = styled(Typography)({
    padding: "0 15px",
    margin: "45px 0px 10px ",
    fontSize: "25px",
    fontFamily: "Open Sans",
  });

  const RawHtml = ({ children = "", tag: Tag = "div", ...props }) => (
    <Tag {...props} dangerouslySetInnerHTML={{ __html: children }} />
  );

  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto", minHeight: "180vh" }}>
      <MyTypographyMain align='center'>Meet Our Expert</MyTypographyMain>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={myImage} src={profile?.userProfileUrl} alt='' />
              </Box>
              <MyTypography align='center'>{profile?.fullname}</MyTypography>
              <Typography
                sx={{ fontSize: 14, fontFamily: "Open Sans" }}
                color='text.secondary'
                gutterBottom
                align='center'
              >
                {profile?.jobPosition}
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontFamily: "Open Sans" }}
                color='text.secondary'
                gutterBottom
                align='center'
              >
                {profile?.jobCompany}
              </Typography>

              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{textAlign:"center"}} >
                  <i className='fa-solid fa-envelope'></i> {profile?.email}
                </Box>
                <Box></Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                {profile?.facebookUrl && (
                  <a target='_blank' href={profile?.facebookUrl}>
                    <i className=' owner-link fa-brands fa-facebook-f'></i>
                  </a>
                )}

                {profile?.instagram && (
                  <div
                    onClick={() =>
                      window.open(`${profile?.instagram}`, "_blank")
                    }
                  >
                    <i className=' owner-link fa-brands fa-instagram'></i>
                  </div>
                )}
                {profile?.linkedinUrl && (
                  <div
                    onClick={() =>
                      window.open(`${profile?.linkedinUrl}`, "_blank")
                    }
                  >
                    <i className=' owner-link fa-brands fa-linkedin-in'></i>
                  </div>
                )}
                {profile?.webSite && (
                  <div
                    onClick={() =>
                      window.open(`https://${profile?.webSite}`, "_blank")
                    }
                  >
                    <i className=' owner-link fa-solid fa-globe'></i>
                  </div>
                )}

                {profile?.twitter && (
                  <a
                    target='_blank'
                    onClick={(e) => {
                      window.location.href = `https://${profile?.twitter}`;
                    }}
                  >
                    <i className=' owner-link fa-brands fa-twitter'></i>
                  </a>
                )}

                {profile?.email && (
                  <a
                    onClick={(e) => {
                      window.location.href = `mailto:${profile?.email}`;
                    }}
                    target='_blank'
                  >
                    <i className=' owner-link fa-regular fa-envelope'></i>
                  </a>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Box>
            <MyTypography2>About Me</MyTypography2>
            <Typography
              sx={{ fontSize: 16, fontFamily: "Open Sans", padding: "0 15px" }}
              color='text.secondary'
            >
              <RawHtml>{profile?.biography}</RawHtml>
            </Typography>
          </Box>
          <Box sx={{ marginTop: "30px" }}></Box>
        </Grid>
      </Grid>
      {profile?.providedServices && (
        <div>
          <MyTypography2>Provided Services</MyTypography2>
          <Typography sx={{ fontFamily: "Open Sans", padding:"0 15px" }} color='text.secondary'>
            <RawHtml>{profile?.providedServices}</RawHtml>
          </Typography>
        </div>
      )}

      <MyTypography2>Uploaded Samples</MyTypography2>

      <Grid container spacing={4} sx={{padding:"0 15px"}}>
        {allCourse?.map((index, key) => (
          <Grid sx={{ minHeight: "360px" }} item xs={12} sm={4}>
            <MentorCard index={index} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Profile;
