import React, { useState } from "react";

import { Box, Card, CardContent, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";

function AllTeamCard({ index }) {
  const myLink = {
    color: "#333333",
  };



  const [biography, setBiography] = useState(false);

  const MyTypography1 = styled(Typography)({
    fontFamily: "Open Sans",
    margin: "15px 0px",
    fontSize: "18px",
  });
  const MyTypography3 = styled(Typography)({
    fontFamily: "Open Sans",
    marginTop: "15px",
    fontSize: "14px",
    fontWeight: "700",
  });

  const myImage = {
    width: "150px",
    height: "150px",
    borderRadius: "50%",
  };

  return (
    <Card sx={{minHeight:"400px "}}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img style={myImage} src={index?.userProfileUrl} alt='' />
        </Box>
        <Link style={myLink} to={`/profile/${index?.id}`}>
          <MyTypography1
            sx={{ ":hover": { textDecoration: "underLine" } }}
            align='center'
            variant='h5'
            component='div'
          >
            {index?.fullname}
          </MyTypography1>
        </Link>
        <Typography
        align="center"
          sx={{
            fontSize: 14,
            fontFamily: "Open Sans",
            ":hover": { textDecoration: "underLine" },
            cursor: "pointer",
            transition: 5,
          }}
          color='text.secondary'
          gutterBottom
          onClick={() => setBiography(!biography)}
        >
          {biography
            ? `${index?.biography.substring(0, 100)}`
            : `${index?.biography.substring(0, 60)}`}
        </Typography>

        <MyTypography3 align='center'>{index?.jobPosition}</MyTypography3>

        <Typography
          on
          align='center'
          sx={{ fontSize: 14, fontFamily: "Open Sans", marginBottom: "15px" }}
          color='text.secondary'
          gutterBottom
        >
          {index?.jobCompany}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {index?.facebookUrl && (
            <a target='_blank' href={index?.facebookUrl}>
              <i className=' owner-link fa-brands fa-facebook-f'></i>
            </a>
          )}

          {index?.instagram && (
            <div onClick={() => window.open(`${index?.instagram}`, "_blank")}>
              <i className=' owner-link fa-brands fa-instagram'></i>
            </div>
          )}
          {index?.linkedinUrl && (
            <div onClick={() => window.open(`${index?.linkedinUrl}`, "_blank")}>
              <i className=' owner-link fa-brands fa-linkedin-in'></i>
            </div>
          )}
          {index?.webSite && (
            <div
              onClick={() => window.open(`https://${index?.webSite}`, "_blank")}
            >
              <i className=' owner-link fa-solid fa-globe'></i>
            </div>
          )}

          {index.twitter && (
            <a
              target='_blank'
              onClick={(e) => {
                window.location.href = `https://${index?.twitter}`;
              }}
            >
              <i className=' owner-link fa-brands fa-twitter'></i>
            </a>
          )}

          {index?.email && (
            <a
              onClick={(e) => {
                window.location.href = `mailto:${index?.email}`;
              }}
              target='_blank'
            >
              <i className=' owner-link fa-regular fa-envelope'></i>
            </a>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default AllTeamCard;
