import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import {Link} from "react-router-dom"
import styled from "@emotion/styled";
import GetData from "../../../Api/getData";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MentorCard from "./MentorCard";

function MentorMain() {

  GetData(0,3);

  const { allCourse } = useSelector((state) => state.allCourse);



  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
      {/* <MyTypography
        onClick={goToAllPage}
        align='center'
        sx={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          justifyContent: "center",
          margin: "110px 0 40px",
          
        }}
      >
        Digital Product Summary Samples
      </MyTypography>{" "} */}
      <Grid container spacing={4}>
      {allCourse?.map((index, key) => (
            <Grid key={key}  sx={{minHeight:"360px"}} item xs={12} sm={4}>
              <MentorCard index={index} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default MentorMain;
