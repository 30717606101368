import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  ListItem,
  Stack,
  Toolbar,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/image/LOGO of HEKAYAM.com-Logo.drawio.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

function Header() {
  const myListButton = {
    width: "90px",
    height: "30px",
    backgroundColor: "#F36E26",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    color: "white",
    fontSize: "14px",
    padding: "0 3px",
    textDeceration: "none",
  };
  const myListButton3 = {
    width: "160px",
    height: "30px",
    backgroundColor: "#4691DA",
    // backgroundColor:"#9b0a0a",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    color: "white",
    fontSize: "13px",
    padding: "0 3px",
    '@media screen and (min-width: 992px) and (max-width: 1200px)': {
      fontSize: "12px"
    }
  };

  const myListButton1 = {
    color: "black",
    transition: 5,
    // fontSize:"14px"
  };

  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };

  const MobileBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    minWidth: "200px",
    marginTop: "20px",
  });

  const MobileLink = {
    display: "none",
  };

  const navigate = useNavigate();

  const goTo = () => {
    navigate("/");
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const MyToolbar = styled(Toolbar)({
    minHeight: "40px !important",
  });

  return (
    <React.Fragment>
      {/* <AppBar position='sticky' sx={{ bgcolor: "#1C2128", mb: "60px" }}> */}
      <AppBar
        position='sticky'
        sx={{ bgcolor: "#E4E4E4", boxShadow: "0", mb: "26px", height: "45px" }}
      >
        <MyToolbar>
          <Container>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6} lg={4}>
                <Box
                  onClick={goTo}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    cursor: "pointer",
                  }}
                >
                  <Box
                    onClick={goTo}
                    component='img'
                    sx={{
                      height: {
                        sm: 50,
                        xs: 25,
                      },
                    }}
                    alt='Your logo.'
                    src={Logo}
                  />
                  <Typography sx={{ fontSize: 20 }}></Typography>
                </Box>
              </Grid>
              <Grid item xs={4.7} lg={5}>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {/* //////////////////////// */}
                  <NavLink style={myListButton1} to='/price'>
                 
                      {" "}
                      Pricing
                
                  </NavLink>

                  {/* <div
                    className='hoverable-div'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <p className="nav-menuLetter" >Menu <i class="fa-solid fa-chevron-down"></i></p>
                    {isHovered && (
                      <div>
                        <ul>
                          <li>Software Requirement Analysis</li>
                          <li>Solution Architecture </li>
                          <li>Analyse a Business</li>
                          <li>Our Advantages</li>
                          <li>Top Templates (All)</li>
                          <li>Why hekayam.com</li>
                          <li>What people says about us.</li>
                          <li>What people says about us.</li>
                          <li>Contact</li>
                        </ul>
                      </div>
                    )}
                  </div> */}

                  <NavLink style={myListButton1} to='/samples'>
                    Samples
                  </NavLink>
                  <NavLink style={myListButton1} to='/polist'>
                    Experts
                  </NavLink>
                  <NavLink style={myListButton3} to='/productowner'>
                    Become Product Owner
                  </NavLink>

                  {/* //////////////////////// */}
                </Box>
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "end",
                  }}
                >
                  <MenuIcon
                    onClick={() => handleClick()}
                    sx={{
                      color: "black",
                      fontSize: { xs: "30px", sm: "40px" },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </MyToolbar>
        <Drawer
          aria-label='muiDrawer'
          anchor='left'
          open={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          onClick={() => setIsOpen(!isOpen)}
        >
          <MobileBox>
          <NavLink style={myListButton1} to='/price'>
 
            
                Pricing
         
            </NavLink>
            <NavLink style={myListButton1} to='/samples'>
              Samples
            </NavLink>
            <NavLink style={myListButton1} to='/polist'>
              Experts
            </NavLink>
            <NavLink style={myListButton3} to='/productowner'>
              Become Product Owner
            </NavLink>
         
          </MobileBox>
        </Drawer>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
