import React from "react";
import { Box, Button, Grid, TextField } from "@mui/material";

import { useFormik, ErrorMessage } from "formik";
import * as yup from "yup";

import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TabPanel(index) {
  const { productName } = index;

  const filedStyle = {
    width: "100%",
  };

  const notify = () =>
    toast.success(
      " You have registered successfully. We will contact you in a short time.",
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  const validationSchema = yup.object({
    fullName: yup
      .string()
      .required("FullName name is required")
      .min(3, "Please enter a name more than 1 character"),
    country: yup
      .string()
      .required("Country is required")
      .min(3, "Please enter a name more than 1 character"),
    organization: yup
      .string()
      .required("organization is required")
      .min(3, "Please enter a name more than 1 character"),
    position: yup
      .string()
      .required("positionis required")
      .min(3, "Please enter a name more than 1 character"),
    number: yup
      .number()
      .typeError("Mobile Number is required")
      .required("Number is required")
      .min(0, "Please enter mobile number character"),
    whatsapp: yup
      .number()
      .typeError("Whatsapp Number is required")
      .required("Whatsapp Number is required")
      .min(0, "Please enter whatsapp number character"),

    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email name is required"),
    multiline: yup
      .string()
      .required("Write your question")
      .min(5, "Must be more than 10 characters"),
  });

  const formik = useFormik({
    initialValues: {
      productName: `${productName}`,
      fullName: " ",
      country: " ",
      organization: " ",
      position: " ",
      number: " ",
      whatsapp: " ",
      email: " ",
      multiline: " ",
      productName: " ",
    },
    onSubmit: (values, { resetForm }) => {
      resetForm({ values: "" });
      emailjs
        .send(
          "service_j3bjl59",
          "template_9pfknqp",

          values,
          "UFF_E1n1rDlHxDoHz"
        )

        .then(
          (result) => {
            console.log(result.text);
            notify();
          },
          (error) => {
            console.log(error.text);
          }
        );
    },
    validationSchema: validationSchema,
  });

  return (
    <React.Fragment>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <Box sx={{ padding: "0px 10px" }}>
        <form
          className='simpleForm'
          sx={{ gap: "20px" }}
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                className='inputRounded'
                name='fullName'
                id='fullName'
                label='Full Name'
                multiline
                maxRows={4}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
                style={filedStyle}
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className='inputRounded'
                name='email'
                type='email'
                id='email'
                label='Email'
                variant='outlined'
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                style={filedStyle}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className='inputRounded'
                name='number'
                type='number'
                id='number'
                label='Mobile Number'
                variant='outlined'
                value={formik.values.number}
                onChange={formik.handleChange}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
                style={filedStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className='inputRounded'
                name='whatsapp'
                type='number'
                id='whatsapp'
                label='Whatsapp Number'
                variant='outlined'
                value={formik.values.whatsapp}
                onChange={formik.handleChange}
                error={
                  formik.touched.whatsapp && Boolean(formik.errors.whatsapp)
                }
                helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                style={filedStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className='inputRounded'
                name='country'
                id='country'
                label='Country'
                variant='outlined'
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                style={filedStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className='inputRounded'
                name='organization'
                id='organization'
                label='Organization'
                variant='outlined'
                value={formik.values.organization}
                onChange={formik.handleChange}
                error={
                  formik.touched.organization &&
                  Boolean(formik.errors.organization)
                }
                helperText={
                  formik.touched.organization && formik.errors.organization
                }
                style={filedStyle}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className='inputRounded'
                name='position'
                id='position'
                label='Position'
                variant='outlined'
                value={formik.values.position}
                onChange={formik.handleChange}
                error={
                  formik.touched.position && Boolean(formik.errors.position)
                }
                helperText={formik.touched.position && formik.errors.position}
                style={filedStyle}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                className='inputRounded'
                id='outlined-multiline-flexible'
                label='Your comment...'
                name='multiline'
                value={formik.values.multiline}
                onChange={formik.handleChange}
                error={
                  formik.touched.multiline && Boolean(formik.errors.multiline)
                }
                helperText={formik.touched.multiline && formik.errors.multiline}
                multiline
                rows={4}
                style={filedStyle}
              />
            </Grid>

            <TextField
              sx={{ display: "none" }}
              className='inputRounded'
              id='outlined-multiline-flexible'
              label='Your comment...'
              name='multiline'
              value={`${productName}`}
            />

            <Grid item xs={12} sm={12}>
              <Button
                color='primary'
                variant='contained'
                fullWidth
                type='submit'
              >
                Register
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </React.Fragment>
  );
}

export default TabPanel;
