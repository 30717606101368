import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TabPanel1 from "./TabPanel";
import {
  Container,
  FormGroup,
  Grid,
  Tab,
  TextField,
  styled,
} from "@mui/material";
import QuestionTab from "../../contact/ContactSimple/ContactBox";

import { useFormik, ErrorMessage } from "formik";
import * as yup from "yup";
//   import "./style.css";
import emailjs from "@emailjs/browser";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function ProductContact(props) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { openCntc, setOpenCntc, detail } = props;

  

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const handleOpen = () => setOpenCntc();
  const handleClose = () => setOpenCntc();

  return (
   
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={openCntc}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
       
      >
        <Fade  in={openCntc} >
          <Box sx={style }>
            <Typography align="center" id='transition-modal-title' variant='h6' component='h2'>
              {detail}
            </Typography>
            <Grid container sx={{height:{xs: "400px", sm:"auto", md:"450px" , lg:"auto"}   , overflow:{xs:"scroll" , sm:"auto" , md:"scroll"}     }} >
              <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ width: "100%", typography: "body1"}}>
                  <TabContext  value={value} >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                      
                        onChange={handleChange}
                        aria-label='lab API tabs example'
                      >
                        <Tab label='For Registration' value='1' />
                        <Tab label='For question' value='2' />
                      </TabList>
                    </Box>
                    <TabPanel value='1'>
                      <TabPanel1 productName={detail}  />
                    </TabPanel>
                    <TabPanel value='2'>
                      <QuestionTab />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
 
  );
}

export default ProductContact;
