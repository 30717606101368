import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import GetData from "../../Api/getData";

import { Link, useNavigate } from "react-router-dom";

import MentorCard from "../Mainpage.jsx/Mentor_part/MentorCard";

function AllCourse() {
  GetData(0, 100);
  const { allCourse } = useSelector((state) => state.allCourse);

  const navigate = useNavigate();

  const MyTypography = styled(Typography)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
    margin: "50px 0px ",
  });

  return (
    <>
      <Box sx={{ maxWidth: "800px", margin: "0 auto", minHeight: "70vh" }}>
        <MyTypography>Digital Product Summary Samples</MyTypography>

        <Grid
          container
          spacing={4}
          sx={{ padding: { xs: "0px 15px", sm: "auto" } }}
        >
          {allCourse?.map((index, key) => (
            <Grid sx={{ minHeight: "360px" }} item xs={12} sm={4}>
              <MentorCard index={index} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default AllCourse;
