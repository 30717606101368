import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

function MentorCard({index}) {


    
  const myLink ={
    color:"#333333"
  }

  const navigate = useNavigate();

  const goToAllPage = () => {
    navigate("/allCourse");
  };


  return (
    <>
    
    <div className='mainMentor'>
                <div className='mentorImg'>
                  <img src={index?.headingFileUrl} alt='' />
                </div>
                <div  className='mentorLetter'>
                  <p>{index?.serviceCategoryName}</p>

                 <p onClick={()=>  window.open(`https://hekayam.com/templates/${index?.urlProjectId}/index.html` , '_blank')} >{index?.projectTitle}</p>
                </div>
                <div className='mentor'>
                  <div>
                  <img src={index?.userProfileUrl} alt='' />
                  <Link  style={myLink}  to={`/profile/${index?.fkProductOwnerProfileId
}`} >

                  <p>  {index?.authorFullname}</p>
                  </Link>
            
                  </div>
                {
                  index?.showPrototype != "0" && (<div className='mentorButton '>
                  <p onClick={()=> window.open(`https://hekayam.com/templates/${index?.urlProjectId}/view.html?sc=${index?.urlViewId}` , '_blank') }  className='mentorBtn'> <i className="fa-solid fa-play"></i>  Prototype</p>
                </div>)
                }
                </div>
              </div>
    
    
    </>
  )
}

export default MentorCard