import { Box, Typography } from "@mui/material";
import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
function Footer() {
  return (
  
      <Box sx={{ background: "#1C2128", padding: 3, marginTop: "100px", position:"relative", left:"0", bottom:"0", right:"0" , zIndex:"9" }}>
        <Box
          sx={{
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            fontSize:{xs:"12px", sm:"14px" , lg:"16px"} 
          }}
          align='center'
        >
          <Typography sx={{  display: "flex", alignItems: "center" , fontSize:{xs:"12px", sm:"14px" , lg:"16px"}  }}>
            {" "}
            <CopyrightIcon /> Copyright 2023 Hekayam.com{" "}
          </Typography>{" "}
          <a className='alink' href='mailto:info@hekayam.com'>
            info@hekayam.com
          </a>
        </Box>
      </Box>
   
  );
}

export default Footer;
