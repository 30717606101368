import React from "react";
import { Box, Button, Typography, createTheme, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';

import {  Pagination, Navigation } from "swiper";
import SliderImg1 from "../../assets/SliderImage/Process.gif"
import SliderImg2 from "../../assets/SliderImage/BusinessCard.gif"
import SliderImg3 from "../../assets/SliderImage/Matrix.gif"
import SliderImg4 from "../../assets/SliderImage/StoryCard.gif"





function Part3() {


  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const navigate = useNavigate()

  const handleButtonClick = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1C2128",
        second: "#888888",
      },
      secondary: {
        main: "#373C44",
      },
    },
  });

  const MyTypography4 = styled(Typography)({
    color: theme.palette.primary.second,
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily:"Open Sans",
    "@media (max-width:665px)": {
      fontSize: "16px",
    },
    marginBottom: "20px",
  });

  const MyButton = styled(Button)({
    fontSize: "16px",
    height: "56px",
    width: "222px",
    backgroundColor: "#4691db",
    color: "white",
    fontFamily: "Open Sans",
    fontWeight: 700,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4691db",
      color: "white",
    },
    "@media (max-width:665px)": {
      width: "150px",
      height: "45px",
    },
  });

  return (
    <>
      <MyTypography4 align='center'>
      Project Details, Business Case, User Story Cards, Backlog/Sprint Matrix, <br /> API Cards, Database Card, Entity Diagram, Process Card      </MyTypography4>
      {/* <Container sx={{margin:0, padding:0}}> */}

      {/* ////////////////////Part 2 End /////////////////////////*/}

      {/* Addition Part Start */}

      <Box sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 3 }}>
        
          <MyButton  onClick={() => navigate("/samples")} sx={{ textTransform: "none" }}>View Samples</MyButton>
      
        <MyButton onClick={handleButtonClick} sx={{ textTransform: "none" }}>
          {" "}
          Contact{" "}
        </MyButton>
      </Box>
      {/* <Box sx={{display:"flex", justifyContent:"center"}}>

        <MyButton>Contact Us</MyButton>
        </Box> */}

      <Box
        sx={{
          maxWidth:"800px",
          margin:"0 auto",
          marginTop: "38px",
          marginBottom: "78px",
          display: "flex",
          justifyContent: "center",
          border: "1px solid lightgray",
          padding:"5px",
          borderRadius:"3px"
        }}
      >
         <Swiper 
    
    spaceBetween={30}
        centeredSlides={true}
  
     
        pagination={pagination}
        navigation
        modules={[Pagination, Navigation]}
        className="mySwiper1"
      
        loop={true}
      >
        <SwiperSlide><img className="myImage" src={SliderImg1} alt="" /></SwiperSlide>
        <SwiperSlide><img className="myImage" src={SliderImg2} alt="" /></SwiperSlide>
        <SwiperSlide><img className="myImage" src={SliderImg3} alt="" /></SwiperSlide>
        <SwiperSlide><img className="myImage" src={SliderImg4} alt="" /></SwiperSlide>
      </Swiper>
        
      </Box>
    </>
  );
}

export default Part3;
