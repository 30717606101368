import React from "react";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  ListItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import img from "./1588275874198_82CFE8110D84F.jfif";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GetData from "../../../Api_mentor/getData";
import AllTeamCard from "../../AllTeam_Page/AllTeamCard";

function Expert() {
  const navigation = useNavigate();

  const MyTypography1 = styled(Typography)({
    fontFamily: "Open Sans",
    margin: "15px 0px",
    fontSize: "18px",
  });
  const MyTypography3 = styled(Typography)({
    fontFamily: "Open Sans",
    margin: "15px 0px",
    fontSize: "14px",
    fontWeight: "700",
  });

  const MyTypography = styled(Typography)({
    color: "#333333",
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Open Sans",
    textAlign: "center",
    margin: "50px 0px 10px",
    cursor: "pointer",
    // margin: "30px",

    "@media (maxWidth:665px)": {
      fontSize: "20px",
    },
  });

  GetData(0,6);

  const { allMentor } = useSelector((state) => state.allMentor);

  return (
    <Box sx={{ margin: "0 auto", maxWidth: "800px" }}>
      <MyTypography onClick={() => navigation("./polist")}>
        Meet Our Experts
      </MyTypography>
      <Typography sx={{marginBottom:"40px"}} align="center">
        Our expert can foster your business to be successful and <br /> find innovative solutions
      </Typography>

      <Grid container spacing={2}>
        {allMentor.map((index, key) => (
          <Grid sx={{ minHeight: "445px" }} key={key} item xs={12} sm={4}>
            <AllTeamCard index={index}/>
          </Grid>
        ))}
      </Grid>

    </Box>
  );
}

export default Expert;
