import React from "react";
import {
  Box,
  Button,
  TextField,
} from "@mui/material";
import img from "./letter.jpg";
import { useFormik, ErrorMessage } from "formik";
import * as yup from "yup";
import "./style.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactBox() {
  const notify = () =>
    toast.success(
      "Thank you. Your message has been sent successfully",
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("First name is required")
      .min(3, "Please enter a name more than 1 character"),
    lastName: yup
      .string()
      .required("Last name is required")
      .min(3, "Please enter a name more than 1 character"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email name is required"),
    multiline: yup
      .string()
      .required("Write your question")
      .min(5, "Must be more than 10 characters"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      multiline: "",
    },
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      resetForm({ values: "" });
      emailjs
        .send(
          "service_j3bjl59",
          "template_z2sh5xz",
          values,
          "UFF_E1n1rDlHxDoHz"
        )
        .then(
          (result) => {
            console.log(result.text);
            notify();
          },
          (error) => {
            console.log(error.text);
          }
        );
    },
    validationSchema: validationSchema,
  });

  return (
    <Box sx={{ padding: "0px 10px" }}>
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <form
        className='simpleForm'
        sx={{ gap: "20px" }}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          className='inputRounded'
          name='firstName'
          id='name'
          label='FirstName'
          multiline
          maxRows={4}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          className='inputRounded'
          name='lastName'
          id='surname'
          label='Last Name'
          variant='outlined'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          className='inputRounded'
          name='email'
          type='email'
          id='email'
          label='What is your email ?'
          variant='outlined'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          className='inputRounded'
          id='outlined-multiline-flexible'
          label='Your question...'
          name='multiline'
          value={formik.values.multiline}
          onChange={formik.handleChange}
          error={formik.touched.multiline && Boolean(formik.errors.multiline)}
          helperText={formik.touched.multiline && formik.errors.multiline}
          multiline
          rows={4}
        />
        <Button color='primary' variant='contained' fullWidth type='submit'>
          Send Message
        </Button>
      </form>
    </Box>
  );
}

export default ContactBox;
